import React from 'react'

import { useTranslation, Trans } from 'react-i18next'
import styled from 'styled-components'

import Text from '@nickel/ui/components/legacy/Text'
import { Color, FontSize } from '@nickel/ui/tokens'

import Link from '../../../../components/Link/Link'
import { FormType } from '../../../../types/forms'

import Logo from './Logo'

type Props = {
    formType: FormType
}

const Container = styled.header`
    text-align: center;
    border-bottom: 1px solid ${Color.OUTLINE};
    padding-bottom: 1rem;
`

const linkMap: Record<FormType, string> = {
    [FormType.FATCA]: 'url.contact-form',
    [FormType.MAIL]: 'url.contact-form',
    [FormType.PHONE]: 'url.phone-number-change',
    [FormType.TRANSACTION_DISPUTE]: 'url.contact-form',
    [FormType.WITHDRAWAL_DISPUTE]: 'url.contact-form'
}

const Header = ({ formType }: Props) => {
    const { t } = useTranslation('forms')
    return (
        <>
            <Container>
                <Logo />
                <Text fontSize={FontSize.XLARGE}>{t('edit.title', { context: formType })}</Text>
            </Container>
            <p>
                <Text>
                    <Trans
                        i18nKey="forms:edit.instructions_1"
                        context={formType}
                        components={{
                            anchor: <Link href={t('url.blocking-card')} />
                        }}
                    />
                </Text>
                {FormType.TRANSACTION_DISPUTE === formType && (
                    <>
                        <br />
                        <Text>{t('edit.instructions_2_TRANSACTION_DISPUTE')}</Text>
                    </>
                )}
                <a href={t(linkMap[formType])} target="_blank" rel="noreferrer">
                    {t(linkMap[formType])}
                </a>
                {FormType.TRANSACTION_DISPUTE !== formType && FormType.MAIL !== formType && (
                    <>
                        <br />
                        <Text>{t('edit.instructions_2', { context: formType })}</Text>
                    </>
                )}
            </p>
            {formType === FormType.WITHDRAWAL_DISPUTE && <p>{t('edit.instructions_3_WITHDRAWAL_DISPUTE')}</p>}
        </>
    )
}

export default Header
