import React, { memo } from 'react'

import { useTranslation } from 'react-i18next'

import Fieldset from '@nickel/ui/components/legacy/form/Fieldset'

const Privacy = () => {
    const { t } = useTranslation('forms')
    return (
        <Fieldset legend={t('fatca.privacy.title')}>
            <p>{t('fatca.privacy.content')}</p>
        </Fieldset>
    )
}

export default memo(Privacy)
