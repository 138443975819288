import React, { memo } from 'react'

import { useTranslation } from 'react-i18next'

import Fieldset from '@nickel/ui/components/legacy/form/Fieldset'

const Certification = () => {
    const { t } = useTranslation('forms')
    return (
        <Fieldset legend={t('fatca.certification.title')}>
            <p>{t('fatca.certification.content')}</p>
        </Fieldset>
    )
}

export default memo(Certification)
