import React, { memo } from 'react'

import { useTranslation } from 'react-i18next'

import useFormField from '@nickel/forms/hooks/useFormField'
import Field from '@nickel/ui/components/legacy/form/Field'
import Input from '@nickel/ui/components/legacy/form/Input'

const name = 'name-used'

const NameUsed = () => {
    const { t } = useTranslation('forms')
    const { error, inputProps } = useFormField<string>(name)
    return (
        <Field error={error} htmlFor={name} subLabel={t('fatca.name-used.sublabel')}>
            <Input {...inputProps} name={name} label={t('fatca.name-used.label')} />
        </Field>
    )
}

export default memo(NameUsed)
