import React from 'react'

import { Col, Row } from 'react-grid-system'
import { useTranslation } from 'react-i18next'

import { FieldValidationRule } from '@nickel/forms/types'
import BirthDate from '@nickel/kyc/fields/basicInfo/BirthDate'
import CheckboxGroup from '@nickel/ui/components/legacy/form/Checkbox/Group'
import Fieldset from '@nickel/ui/components/legacy/form/Fieldset'

import CheckboxField from '../../../components/Checkbox'
import { CivilityEditable } from '../../../components/Civility'
import { Disputes } from '../../../components/Disputes/Disputes'
import FraudCommentTextArea from '../../../components/FraudCommentTextArea'

import Styled from './styles'

const Editable = () => {
    const checkboxRules: FieldValidationRule<boolean>[] = [
        {
            validator: (val: boolean) => val,
            message: (val?: boolean) => String(val)
        }
    ]
    const { t } = useTranslation()
    return (
        <>
            <CivilityEditable />
            <Row>
                <Col xs={12}>
                    <Fieldset legend={t('fields:nickel-information')}>
                        <BirthDate isChild={false} placeholder={t('fields:date-placeholder')} />
                    </Fieldset>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Fieldset legend={t('forms:checkbox-group-title')}>
                        <Styled.CheckboxInfo>
                            {t('forms:checkbox-info-1')}
                            <br />
                            {t('forms:checkbox-info-2')}
                        </Styled.CheckboxInfo>
                        <Styled.CheckboxGroupContainer>
                            <CheckboxGroup>
                                <CheckboxField name="blocked-card" rules={checkboxRules} />
                                <CheckboxField name="held-card" rules={checkboxRules} />
                            </CheckboxGroup>
                        </Styled.CheckboxGroupContainer>
                    </Fieldset>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <FraudCommentTextArea />
                </Col>
            </Row>
            <Disputes />
        </>
    )
}
export default Editable
